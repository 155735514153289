import { t } from '@nodal/i18n';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { ProgressBar } from '@nodal/uikit/components/ProgressBar';
import { SurveyCard } from '@nodal/uikit/components/SurveyCard';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';

import { EditTopSection } from './EditTopSection';
import { EditVideo } from './EditVideo';

import type { EditFormProps, EditSectionProps } from './EditForm.interface';
import type {
  ProfileData,
  QuestionData,
} from '@core/flows/MatchProfile/MatchProfile.interface';

const EditOtherSection = <T extends ProfileData>({
  questions,
  values,
}: EditSectionProps<T>) => {
  const renderSurveyClassName = useCallback(
    (type?: string) =>
      classNames(
        'pt-0 border-none',
        type === 'select' || type === 'select_multiple' || type === 'text'
          ? 'w-full sm:w-4/6 '
          : 'w-full',
        type === 'textarea' && 'pb-0',
      ),
    [],
  );

  return (
    <div className="flex overflow-y-auto flex-col gap-10 py-0 first:pt-8 w-full h-full min-h-85 text-left bg-white rounded-lg sm:h-full sm:min-h-full sm:max-h-100-modal">
      {questions.map(
        ({
          value,
          label,
          type,
          choices,
          description,
          placeholder,
          disabled,
        }: QuestionData<T>) => (
          <div
            key={value?.toString()}
            className={`relative last:border-none ${
              type !== 'textarea'
                ? 'border-b border-grey-forest-100'
                : 'last:pb-14'
            }`}
          >
            <SurveyCard
              label={label}
              value={value?.toString()}
              type={type}
              choices={choices}
              error={false}
              description={description}
              placeholder={placeholder}
              className={renderSurveyClassName(type)}
              disabled={disabled ? disabled(values) : false}
              optionsPosition={value === 'religion' ? 'top' : 'bottom'}
            />
          </div>
        ),
      )}
    </div>
  );
};

export const EditForm = <T extends ProfileData>({
  initialValues,
  questions,
  validationSchema,
  onSubmit,
  section,
  role,
  uploadProgress,
}: EditFormProps<T>) => {
  const renderFormComponent = useCallback(
    (values: T) => {
      switch (section) {
        case 'video':
          return <EditVideo questions={questions} />;
        case 'personalInformation':
          return (
            <EditTopSection questions={questions} role={role} values={values} />
          );
        default:
          return <EditOtherSection questions={questions} values={values} />;
      }
    },
    [questions, section, role],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Form className="flex flex-col col-span-6 justify-start items-start w-full h-full ">
          {renderFormComponent(values)}
          <ProgressBar progress={uploadProgress} />
          <div className="py-3 px-8 ml-auto w-full text-right border-t border-grey-forest-200">
            <ButtonLoading
              type="submit"
              variant="primary"
              className="justify-center ml-auto w-full sm:w-max"
              loading={isSubmitting}
            >
              {t('Submit')}
            </ButtonLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};
