import {
  AnnotationIcon,
  BookOpenIcon,
  CheckCircleIcon,
  GlobeAltIcon,
  HandIcon,
  HeartIcon,
  PencilAltIcon,
  ShieldCheckIcon,
  StarIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import * as Yup from 'yup';

import { Airplane, HeartBeat, Hospital, PregnancyIcon } from '@core/assets';
import { convertToInches } from '@core/utils';
import {
  isInRelationship,
  dataWithSamePartnerAddress,
  dataWithSingleParentNoPartner,
} from '@core/utils/profile';

import { placeTypeKeys } from './placeTypeKeys';

import type {
  Choice,
  DonorProfileInformation,
  ParentProfileInformation,
  AddressValue,
  ProfileInformationWithSections,
} from './MatchProfile.interface';
import type { ApiModel, DonorUser } from '@nodal/api';
import type { ReligionPreferenceEnum } from '@nodal/api/core';
import type { AnyObject } from 'yup/lib/types';

export const radioChoices = [
  {
    label: t('Yes'),
    value: 'true',
    icon: CheckCircleIcon,
  },
  {
    label: t('No'),
    value: 'false',
    icon: XCircleIcon,
  },
];

type ChoicesWithValueType<T> = Array<
  Omit<Choice, 'value'> & {
    value: T;
  }
>;

export const relationshipStatusChoices: ChoicesWithValueType<ApiModel.RelationshipStatusEnum> =
  [
    {
      label: t('Single'),
      value: 'sin',
      badge: t('Single'),
    },
    {
      label: t('In relationship'),
      value: 'rel',
      badge: t('Partnered'),
    },
    {
      label: t('Engaged'),
      value: 'eng',
      badge: t('Engaged'),
    },
    {
      label: t('Married'),
      value: 'mar',
      badge: t('Married'),
    },
    {
      label: t('Divorced'),
      value: 'div',
      badge: t('Single'),
    },
    {
      label: t('Separated'),
      value: 'sep',
      badge: t('Single'),
    },
    {
      label: t('Widowed'),
      value: 'wid',
      badge: t('Single'),
    },
    {
      label: t('Other'),
      value: 'oth',
      badge: t('Other'),
    },
  ];

export const relationshipStatusPreference: ChoicesWithValueType<ApiModel.RelationshipPreferenceEnum> =
  [
    {
      value: 'single',
      label: t('Single'),
      badge: t('Single'),
    },
    {
      value: 'partnered',
      label: t('Partnered'),
      badge: t('Partnered'),
    },
    {
      value: 'no_preference',
      label: t('No Preference'),
      badge: t('No Preference'),
    },
  ];

export const militaryBagdes: Choice[] = [
  {
    label: t('Yes'),
    value: 'true',
    badge: t('Military'),
  },
  {
    label: t('No'),
    value: 'false',
  },
];

export const sexualBadges: Choice[] = [
  {
    value: 'true',
    label: t('Yes'),
    badge: t('LGBTQ+'),
  },
  {
    value: 'false',
    label: t('No'),
  },
];

export const agencyBadges: ChoicesWithValueType<ApiModel.AgencyUsageEnum> = [
  {
    value: 'agency',
    label: t('Yes'),
    badge: t('Agency'),
  },
  {
    value: 'independent',
    label: t('No'),
    badge: t('Independent'),
  },
];

export const importanceChoices: ChoicesWithValueType<ApiModel.ImportanceEnum> =
  [
    {
      value: 'not',
      label: t('Not important'),
    },
    {
      value: 'imp',
      label: t('Important'),
    },
    {
      value: 'mad',
      label: t('Mandatory'),
    },
  ];

export const afterDeliveryRelationshipChoices: ChoicesWithValueType<ApiModel.AfterDeliveryRelationshipEnum> =
  [
    {
      value: 'no_contact',
      label: t('No contact after delivery'),
    },
    {
      value: 'regular_contact',
      label: t('Regular contact after delivery'),
    },
    {
      value: 'occasional_updates',
      label: t('Occasional updates - ie birthdays and holidays'),
    },
    {
      value: 'time_will_tell',
      label: t('See what organically happens'),
    },
    {
      value: 'not_sure_yet',
      label: t('Not sure yet'),
    },
  ];

export const comfortLevelChoices: ChoicesWithValueType<ApiModel.ComfortTravelingEnum> =
  [
    {
      value: '-2',
      label: t('Very uncomfortable'),
    },
    {
      value: '-1',
      label: t('Uncomfortable'),
    },
    {
      value: '0',
      label: t('Neutral'),
    },
    {
      value: '1',
      label: t('Comfortable'),
    },
    {
      value: '2',
      label: t('Very comfortable'),
    },
  ];

export const deliveryCareChoices: ChoicesWithValueType<ApiModel.DeliveryCarePreferenceEnum> =
  [
    {
      value: 'obgyn',
      label: t('OBGYN'),
    },
    {
      value: 'midwife',
      label: t('Midwife'),
    },
    {
      value: 'no_preference',
      label: t('No preference'),
    },
  ];

export const reductionChoices: ChoicesWithValueType<ApiModel.TerminationChoice> =
  [
    {
      value: 'on_medic_advice',
      label: t('Willing to terminate based on medical advice'),
      badge: t('Open to Term'),
    },
    {
      value: 'not_willing',
      label: t('Not willing to terminate'),
      badge: t('No Term'),
    },
  ];

export const religionChoices: ChoicesWithValueType<ApiModel.ReligionEnum> = [
  {
    label: t('Christianity'),
    value: 'christianity',
  },
  {
    label: t('Roman Catholicism'),
    value: 'roman_catholicism',
  },
  {
    label: t('Judaism'),
    value: 'judaism',
  },
  {
    label: t('Islam'),
    value: 'islam',
  },
  {
    label: t('Hinduism'),
    value: 'hinduism',
  },
  {
    label: t('Buddhism'),
    value: 'buddhism',
  },
  {
    label: t('Spiritual'),
    value: 'spiritual',
  },
  {
    label: t('Agnostic'),
    value: 'agnostic',
  },
  {
    label: t('Atheist'),
    value: 'atheist',
  },
  {
    label: t('Other'),
    value: 'other',
  },
];

export const religionPreference: ChoicesWithValueType<ReligionPreferenceEnum> =
  [
    ...religionChoices,
    {
      value: 'no_preference',
      label: t('No preference'),
    },
  ];

export const parentsDonorUsageChoices: ChoicesWithValueType<ApiModel.UsingDonorEnum> =
  [
    {
      value: apiEnums.UsingDonorEnum.No,
      label: t('No - not using a donor'),
    },
    {
      value: apiEnums.UsingDonorEnum.Spe,
      label: t('Yes - using a sperm donor'),
    },
    {
      value: apiEnums.UsingDonorEnum.Egg,
      label: t('Yes - using an egg donor'),
    },
    {
      value: apiEnums.UsingDonorEnum.Bot,
      label: t('Yes - using both an egg and sperm donor'),
    },
    {
      value: apiEnums.UsingDonorEnum.Doc,
      label: t("Let's discuss on our call"),
    },
  ];

export const covidVaccinationPreference: ChoicesWithValueType<ApiModel.CovidVaccinationPreferenceEnum> =
  [
    {
      value: 'fully_vaccinated',
      label: t('Fully Vaccinated'),
    },
    {
      value: 'willing_to_be_vaccinated',
      label: t('Willing to be vaccinated'),
    },
    {
      value: 'no_preference',
      label: t('No preference'),
    },
  ];

export const parentsEmbryoRange: ChoicesWithValueType<ApiModel.FrozenEmbryoCountEnum> =
  [
    {
      value: '1-3',
      label: t('1-3'),
    },
    {
      value: '4-6',
      label: t('4-6'),
    },
    {
      value: '7-9',
      label: t('7-9'),
    },
    {
      value: '10+',
      label: t('> 10'),
    },
  ];

export const parentsEmbryoTest: ChoicesWithValueType<ApiModel.EmbryosPgsTestedEnum> =
  [
    {
      value: 'yes',
      label: t('Yes'),
    },
    {
      value: 'no',
      label: t('No'),
    },
    {
      value: 'planning',
      label: t('Planning'),
    },
  ];

export const parentsEmbryoTransferTypeChoices: ChoicesWithValueType<ApiModel.EmbryoTransferTypeEnum> =
  [
    {
      value: 'SET',
      label: t('SET'),
    },
    {
      value: 'DET',
      label: t('DET'),
    },
  ];

export const deliveryLocationPreference: ChoicesWithValueType<ApiModel.DeliveryLocationPreferenceEnum> =
  [
    {
      value: 'home_birth',
      label: t('Home birth'),
    },
    {
      value: 'birthing_center',
      label: t('Birthing center'),
    },
    {
      value: 'hospital',
      label: t('Hospital'),
    },
    {
      value: 'no_preference',
      label: t('No preference'),
    },
  ];

export const agencyChoices: ChoicesWithValueType<ApiModel.AgencyUsageEnum> = [
  {
    value: 'agency',
    label: t('Agency'),
    badge: t('Agency'),
  },
  {
    value: 'independent',
    label: t('Independent'),
    badge: t('Independent'),
  },
  {
    value: 'case_management_lite',
    label: t('Case management lite'),
    badge: t('Independent'),
  },
  {
    value: 'undecided',
    label: t('Undecided'),
    badge: t('Independent'),
  },
];

export const idealStartChoices: ChoicesWithValueType<ApiModel.IdealStartEnum> =
  [
    {
      value: 'asap',
      label: t('As soon as possible'),
    },
    {
      value: 'soon',
      label: t('3-6 months'),
    },
    {
      value: 'later',
      label: t('6-12 months'),
    },
    {
      value: 'eventually',
      label: t('1 year+'),
    },
  ];

export const totalHouseholdIncomeChoices: ChoicesWithValueType<ApiModel.HouseholdIncomeEnum> =
  [
    {
      value: '<35000',
      label: t('Less than $35,000'),
    },
    {
      value: '35000-69000',
      label: t('{amount1} to {amount2}', '$35,000', '$69,000'),
    },
    {
      value: '70000-99000',
      label: t('{amount1} to {amount2}', '$70,000', '$99,000'),
    },
    {
      value: '100000-149000',
      label: t('{amount1} to {amount2}', '$100,000', '$149,000'),
    },
    {
      value: '>150000',
      label: t('$150,000 and more'),
    },
  ];

export const addressValues: AddressValue = {
  line1: null,
  line2: null,
  city: null,
  state: null,
  zipcode: null,
  location: null,
};

export const dnrProfileInformation: ProfileInformationWithSections<DonorProfileInformation> =
  {
    personalInformation: {
      transform: (data) => {
        const { body_measurements, ...postData } = data || {};
        const { height_ft, height_in, weight } = body_measurements || {};

        return {
          ...postData,
          weight,
          height: convertToInches(height_ft, height_in),
        };
      },
      questions: [
        {
          value: 'first_name',
          label: t('First Name'),
          type: 'text',
          default: null,
          required: true,
        },
        {
          value: 'address',
          default: addressValues,
          required: true,
        },
        {
          value: 'date_of_birth',
          label: t('Date of Birth'),
          type: 'past_date',
          default: null,
          required: true,
        },
        {
          value: 'occupation',
          label: t('Occupation'),
          type: 'text',
          default: null,
          required: true,
        },
        {
          value: 'partner_occupation',
          label: t(`Partner's Occupation`),
          description: t(`What is your partner's occupation?`),
          type: 'text',
          default: null,
          required: true,
          validationSchema: Yup.string()
            .nullable()
            .when('relationship_status', {
              is: isInRelationship,
              then: Yup.string().nullable().required('This field is required'),
            }),
        },
        {
          value: 'household_income',
          label: t('Annual Household Income'),
          description: t(
            `What was your total household income before taxes during the past 12 months?`,
          ),
          type: 'select',
          choices: totalHouseholdIncomeChoices,
          default: null,
          required: true,
        },
        {
          value: 'experience',
          label: t('Experience'),
          type: 'select',
          choices: [
            {
              value: 'experienced',
              label: t('Experienced'),
            },
            {
              value: 'first_time',
              label: t('First Time'),
            },
          ],
          default: null,
          required: true,
        },
        {
          value: 'previous_c_sections',
          label: t('How many previous c-sections have you had?'),
          type: 'select',
          choices: [
            {
              value: apiEnums.PreviousCSectionsEnum.NUMBER_0,
              label: t('None'),
              badge: t('{cSectionsCount} C-sections', '0'),
            },
            {
              value: apiEnums.PreviousCSectionsEnum.NUMBER_1,
              label: t('One'),
              badge: t('1 C-section'),
            },
            {
              value: apiEnums.PreviousCSectionsEnum.NUMBER_2,
              label: t('Two'),
              badge: t('{cSectionsCount} C-sections', '2'),
            },
          ],
          default: null,
          required: true,
          icon: PregnancyIcon,
        },
        {
          value: 'desired_compensation',
          label: t('Base compensation'),
          type: 'number_tag',
          default: null,
          required: true,
          tag: '$',
        },
        {
          value: 'body_measurements',
          label: t('Weight and Height'),
          type: 'bmi',
          default: {
            weight: null,
            height_in: null,
            height_ft: null,
          },
          required: true,
        },
        {
          value: 'bmi',
          label: t('BMI'),
          default: null,
          required: true,
        },
        {
          value: 'ideal_start',
          label: t('Ideal IVF transfer date'),
          description: t('When is your ideal IVF transfer date?'),
          type: 'select',
          choices: idealStartChoices,
          default: null,
          required: true,
        },
        {
          value: 'relationship_status',
          label: t('Are you in a relationship?'),
          type: 'select',
          choices: relationshipStatusChoices,
          default: null,
          required: true,
          icon: UserIcon,
        },
        {
          value: 'number_of_children',
          label: t('How many children do you have?'),
          type: 'number',
          default: 0,
          required: true,
          icon: UsersIcon,
        },
        {
          value: 'lgbtq_identify',
          label: t('Do you identify as LGBTQ+?'),
          type: 'radio',
          choices: sexualBadges,
          default: null,
          required: true,
          icon: HeartIcon,
        },
        {
          value: 'covid_vaccination',
          label: t('Are you fully vaccinated against COVID19?'),
          type: 'radio',
          choices: [
            {
              label: t('Yes'),
              value: 'true',
              badge: t('Vaccinated'),
            },
            {
              label: t('No'),
              value: 'false',
            },
          ],
          default: null,
          required: true,
          icon: ShieldCheckIcon,
        },
        {
          value: 'is_military',
          label: t(
            'Are you in the military or some way involved with the military?',
          ),
          type: 'radio',
          choices: militaryBagdes,
          default: null,
          required: true,
          icon: ShieldCheckIcon,
        },
      ],
    },
    photos: {
      questions: [
        {
          value: 'profile_photos',
          type: 'photos',
          default: [],
          required: true,
        },
      ],
    },
    video: {
      transform: (data) => ({ ...data, video: data?.video_url ?? null }),
      questions: [
        {
          value: 'video_url',
          type: 'video',
          default: null,
          required: false,
        },
      ],
    },
    wordsFromNodal: {
      questions: [
        {
          value: 'words_from_nodal',
          label: '',
          placeholder: 'A short message about you from Nodal.',
          type: 'textarea',
          default: null,
          required: false,
          icon: HeartIcon,
        },
      ],
    },
    getToKnow: {
      questions: [
        {
          value: 'about',
          label: t('A little about myself'),
          placeholder: t('- about me'),
          type: 'textarea',
          default: null,
          required: true,
          icon: HeartIcon,
        },
        {
          value: 'world_of_surrogacy',
          label: t('What brought you to the world of surrogacy?'),
          placeholder: t('- personal story? - why you want to be a surrogate'),
          type: 'textarea',
          default: null,
          required: true,
          icon: GlobeAltIcon,
        },
        {
          value: 'important_qual_ip',
          label: t(
            'What are the most important qualities you are looking for in an Intended Parent?',
          ),
          placeholder: t('Loving couple? Organized? Mellow?'),
          type: 'textarea',
          default: null,
          required: true,
          icon: StarIcon,
        },
        {
          value: 'donor_support',
          label: t(
            'Who will be your support person through the surrogacy journey?',
          ),
          placeholder: t(
            '- spouse, partner? - friend? - other family member(s)?',
          ),
          type: 'textarea',
          default: null,
          required: true,
          icon: HandIcon,
        },
        {
          value: 'three_words',
          label: t('The three words that best describe me would be....'),
          placeholder: t('- adventurous? - bubbly? - reserved? - driven?'),
          type: 'textarea',
          default: null,
          required: true,
          icon: UserCircleIcon,
        },
        {
          value: 'passionate_about',
          label: t(
            'What is one thing outside of surrogacy you are passionate about?',
          ),
          placeholder: t('- reading? - exercise? - baking sourdough?'),
          type: 'textarea',
          default: null,
          required: true,
          icon: BookOpenIcon,
        },
        {
          value: 'additional_qs',
          label: t(`Anything else you'd like to share with your future IP's?`),
          placeholder: t(
            '- short message directed to the IPs? - anything else you did not already share above',
          ),
          type: 'textarea',
          default: null,
          required: false,
          icon: AnnotationIcon,
        },
      ],
    },
    preferences: {
      questions: [
        {
          value: 'first_time_parents',
          description: t(
            'Importance of being a Surrogate for first time parent(s)?',
          ),
          label: t('Working with first time Parent(s)'),
          type: 'radio_range',
          choices: importanceChoices,
          default: null,
          required: true,
        },
        {
          value: 'lgbtq_comfort',
          description: t('Comfortable working with LGBTQ+ couples?'),
          label: t('LGBTQ+ IP'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'relationship_preference',
          description: t(
            'Do you prefer working with a partnered vs. a single IP?',
          ),
          label: t('Relationship status'),
          type: 'select',
          choices: relationshipStatusPreference,
          default: null,
          required: true,
        },
        {
          value: 'proximity_importance',
          description: t(
            'Importance of working with IPs in same geographic region?',
          ),
          label: t('Living in close proximity'),
          type: 'radio_range',
          choices: importanceChoices,
          default: null,
          required: true,
        },
        {
          value: 'closeness_importance',
          description: t(
            'Importance of creating a close relationship with IPs?',
          ),
          label: t('Strong Relationship'),
          type: 'radio_range',
          choices: importanceChoices,
          default: null,
          required: true,
        },
        {
          value: 'after_delivery_relationship',
          description: t(
            'What type of relationship with your surrogate are you hoping for after delivery?',
          ),
          label: t('Preferred type of relationship after delivery'),
          type: 'select',
          choices: afterDeliveryRelationshipChoices,
          default: null,
          required: true,
        },
        {
          value: 'confidentiality_comfort',
          description: t(
            'Willing to sign a confidentiality agreement with IPs if requested?',
          ),
          label: t('Comfortable signing a confidentiality agreement'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
      ],
    },
    journey: {
      transform: (data) =>
        Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            const clearedPlaceValue =
              placeTypeKeys.find((placeKey) => placeKey === key) &&
              !value?.location;

            const updatedValue = clearedPlaceValue ? null : value;

            return [key, updatedValue];
          }),
          // TODO: Remove if some profile types will be fixed (set as optional).
          // Reported as an issue : https://linear.app/nodal-health/issue/NOD-266/backend-bug-in-api-schema-for-parents-and-donor-profile
        ) as DonorProfileInformation,
      questions: [
        {
          value: 'willing_to_vaccinate',
          description: t(
            'If you are not fully vaccinated against COVID19, are you willing to receive the vaccinations?',
          ),
          label: t('Willing to be vaccinated against COVID-19'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
          hidden: ({ covid_vaccination }: DonorUser['profile']) =>
            covid_vaccination || covid_vaccination?.toString() === 'true',
        },
        {
          value: 'delivery_location_preference',
          description: t('Do you have a delivery location preference?'),
          label: t('Delivery Location Preference'),
          type: 'select',
          choices: deliveryLocationPreference,
          default: null,
          required: true,
        },
        {
          value: 'hospital_address',
          description: t(
            'What is the closest hospital to you with a maternity ward (or what hospital would you go to for the birth)?',
          ),
          label: t('Closest hospital'),
          type: 'place',
          default: addressValues,
          required: true,
          icon: Hospital,
        },
        {
          value: 'clinic_address',
          description: t(
            "If you've been a surrogate before, what clinic did you go to for monitoring your IVF process?",
          ),
          label: t('Monitoring clinic'),
          type: 'place',
          default: addressValues,
          required: false,
          icon: HeartBeat,
          validationSchema: Yup.object()
            .nullable()
            .shape({
              line1: Yup.string().nullable(),
              line2: Yup.string().nullable(),
              state: Yup.string().nullable(),
              zipcode: Yup.string().nullable(),
              city: Yup.string().nullable(),
              location: Yup.array()
                .of(Yup.string())
                .nullable()
                .test(
                  'conditionally-required',
                  t('You must select a location'),
                  function (value) {
                    // NOTE: Using cast here, because Yup TS Types for test context are not updated ('from' is missing)
                    const { from } = this as AnyObject;

                    // NOTE: using 'from' to getting the value from nested schema (one level of nesting)
                    // Ref:  https://github.com/jquense/yup/pull/556#issuecomment-641988687
                    return !(from[1].value.clinic_address.line1 && !value);
                  },
                ),
            }),
        },
        {
          value: 'delivery_care_preference',
          description: t('Do you have a care preference for the delivery?'),
          label: t('Delivery Care Preference'),
          type: 'select',
          choices: deliveryCareChoices,
          default: null,
          required: true,
        },
        {
          value: 'comfort_traveling',
          description: t('Are you comfortable traveling out of state?'),
          label: t('Comfortable traveling out of state'),
          type: 'select',
          choices: comfortLevelChoices,
          default: null,
          required: true,
        },
        {
          value: 'airport_address',
          description: t('What is the closest major airport to you?'),
          label: t('Closest major airport'),
          type: 'place',
          default: addressValues,
          required: true,
          icon: Airplane,
        },
        {
          value: 'termination_choice',
          description: t('Would you be willing to reduce/terminate?'),
          label: t('Reduction/termination'),
          type: 'select',
          choices: reductionChoices,
          default: null,
          required: true,
          icon: CheckCircleIcon,
        },
        {
          value: 'comfort_donated_egg',
          description: t(
            'Are you comfortable working with a donated egg (not yours, not the IPs)?',
          ),
          label: t('Comfortable working with a donated egg (not the IPs)'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'comfort_donated_sperm',
          description: t('Are you comfortable working with donated sperm?'),
          label: t('Comfortable working with donated sperm'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'comfort_donated_embryo',
          description: t('Are you comfortable working with donated embryos?'),
          label: t('Comfortable working with donated embryos'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'single_embryo_comfort',
          description: t(
            'Comfortable working with an IP with a single embryo available?',
          ),
          label: t('Single embryo available'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'multiple_embryo',
          description: t('Are you open to a multiple embryo transfer?'),
          label: t('Multiple embryo transfer'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'willing_to_pump_breast_milk',
          description: t('Willing to pump breast milk and send to IPs?'),
          label: t('Willing to pump breast milk'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'willing_to_restrict_travel',
          description: t(
            'Willing to restrict travel during your pregnancy to match IP expectations?',
          ),
          label: t('Willing to restrict travel during pregnancy'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'independent_journey',
          description: t('Are you open to an independent journey?'),
          label: t('Independent journey'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'religion',
          description: t('Do you identify with any religion?'),
          label: t('Religion'),
          type: 'select',
          choices: religionChoices,
          default: null,
          required: true,
        },
      ],
    },
  };

export const parProfileInformation: ProfileInformationWithSections<ParentProfileInformation> =
  {
    personalInformation: {
      transform: (data) => {
        const { same_partner_address, ...postData } = data || {};

        if (
          same_partner_address &&
          isInRelationship(data.relationship_status)
        ) {
          return dataWithSamePartnerAddress(postData);
        } else if (!isInRelationship(data.relationship_status)) {
          return dataWithSingleParentNoPartner(postData);
        }

        return postData;
      },
      questions: [
        {
          value: 'first_name',
          label: t('First Name'),
          type: 'text',
          default: null,
          required: true,
        },
        {
          value: 'address',
          default: addressValues,
          required: true,
        },
        {
          value: 'date_of_birth',
          label: t('Date of Birth'),
          type: 'past_date',
          default: null,
          required: true,
        },
        {
          value: 'occupation',
          label: t('Occupation'),
          type: 'text',
          default: null,
          required: true,
        },
        {
          value: 'ideal_start',
          label: t('Ideal IVF transfer date'),
          description: t('When is your ideal IVF transfer date?'),
          type: 'select',
          choices: idealStartChoices,
          default: null,
          required: true,
        },
        {
          value: 'partner_first_name',
          label: t('First Name'),
          type: 'text',
          default: null,
          required: true,
          validationSchema: Yup.string()
            .nullable()
            .when('relationship_status', {
              is: isInRelationship,
              then: Yup.string().nullable().required('This field is required'),
            }),
        },
        {
          value: 'same_partner_address',
          label: t('Same address as mine'),
          default: null,
          required: false,
        },
        {
          value: 'partner_address',
          default: addressValues,
          required: true,
        },
        {
          value: 'partner_date_of_birth',
          label: t('Date of Birth'),
          type: 'past_date',
          default: null,
          required: true,
          validationSchema: Yup.string()
            .nullable()
            .when('relationship_status', {
              is: isInRelationship,
              then: Yup.string().nullable().required('This field is required'),
            }),
        },
        {
          value: 'partner_occupation',
          label: t('Occupation'),
          type: 'text',
          default: null,
          required: true,
          validationSchema: Yup.string()
            .nullable()
            .when('relationship_status', {
              is: isInRelationship,
              then: Yup.string().nullable().required('This field is required'),
            }),
        },
        {
          value: 'relationship_status',
          label: t('Relationship Status'),
          type: 'select',
          choices: relationshipStatusChoices,
          default: null,
          required: true,
          icon: UserIcon,
        },
        {
          value: 'lgbtq_identify',
          label: t('Do you identify as LGBTQ+?'),
          type: 'radio',
          choices: sexualBadges,
          default: null,
          required: true,
          icon: HeartIcon,
        },
        {
          value: 'is_military',
          label: t(
            'Are you in the military or some way involved with the military?',
          ),
          type: 'radio',
          choices: militaryBagdes,
          default: null,
          required: true,
          icon: ShieldCheckIcon,
        },
      ],
    },
    photos: {
      questions: [
        {
          value: 'profile_photos',
          type: 'photos',
          default: [],
          required: true,
        },
      ],
    },
    video: {
      transform: (data) => ({ ...data, video: data?.video_url ?? null }),
      questions: [
        {
          value: 'video_url',
          type: 'video',
          default: null,
          required: false,
        },
      ],
    },
    wordsFromNodal: {
      questions: [],
    },
    getToKnow: {
      questions: [
        {
          value: 'about',
          label: t('A little about me/us'),
          type: 'textarea',
          default: null,
          required: true,
          icon: HeartIcon,
        },
        {
          value: 'we_enjoy',
          label: t('I/we enjoy'),
          type: 'textarea',
          default: null,
          required: true,
          icon: HeartIcon,
        },
        {
          value: 'we_relax',
          label: t('To relax I/we'),
          type: 'textarea',
          default: null,
          required: true,
          icon: BookOpenIcon,
        },
        {
          value: 'successful_match',
          label: t('A successful match would be'),
          type: 'textarea',
          default: null,
          required: true,
          icon: StarIcon,
        },
        {
          value: 'note_to_match',
          label: t('A note to my/our surrogate'),
          type: 'textarea',
          default: null,
          required: true,
          icon: PencilAltIcon,
        },
        {
          value: 'additional_qs',
          label: t(
            `Anything else you'd like to share with your future surrogate?`,
          ),
          type: 'textarea',
          default: null,
          required: false,
          icon: AnnotationIcon,
        },
      ],
    },
    preferences: {
      questions: [
        {
          value: 'lgbtq_comfort',
          description: t('Are you comfortable working with LGBTQ+ surrogate?'),
          label: t('LGBTQ+ Surrogate'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'experience_importance',
          description: t('Importance of an experienced surrogate'),
          label: t('Experienced Surrogate'),
          type: 'select',
          choices: importanceChoices,
          default: null,
          required: true,
        },
        {
          value: 'religion_preference',
          description: t(
            'Do you have any religious preferences for your surrogate? (you can select more than one)',
          ),
          label: t('Religion'),
          type: 'select_multiple',
          choices: religionPreference,
          default: null,
          required: true,
        },
        {
          value: 'covid_vaccination_preference',
          description: t(
            'How important is it that your surrogate is vaccinated against COVID-19?',
          ),
          label: t('Vaccinated against COVID-19'),
          type: 'select',
          choices: covidVaccinationPreference,
          default: null,
          required: true,
        },
        {
          value: 'proximity_importance',
          description: t(
            'How important is it that you work with a surrogate in your same geographic region?',
          ),
          label: t('Living in close proximity with IP'),
          type: 'radio_range',
          choices: importanceChoices,
          default: null,
          required: true,
        },
      ],
    },
    journey: {
      transform: (data) => {
        const { agency, clinic, agency_name, clinic_name } = data || {};

        return {
          ...data,
          clinic_name:
            !clinic || clinic?.toString() === 'false' ? null : clinic_name,
          agency_name: agency !== 'agency' ? null : agency_name,
        };
      },
      questions: [
        {
          value: 'using_donor',
          description: t('Are you using a donor (either egg of sperm)?'),
          label: t('Using a donor'),
          type: 'select',
          choices: parentsDonorUsageChoices,
          default: null,
          required: true,
        },
        {
          value: 'frozen_embryo_count',
          description: t('How many embryos do you have frozen?'),
          label: t('Frozen embryo count'),
          type: 'select',
          choices: parentsEmbryoRange,
          default: null,
          required: true,
        },
        {
          value: 'embryos_pgs_tested',
          description: t('Are the embryos PGS tested?'),
          label: t('PGS-tested embryos'),
          type: 'select',
          choices: parentsEmbryoTest,
          default: null,
          required: true,
        },
        {
          value: 'twibling_journey',
          description: t(
            'Are you planning or hoping to do a twibling journey (ie. two journeys at the same time)?',
          ),
          label: t(
            'Hoping for a twibling journey (ie. two journeys at the same time)',
          ),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'embryo_transfer_type',
          description: t(
            'Are you planning on doing a single embryo transfer (SET) or a double embryo transfer (DET)?',
          ),
          label: t('Embryo Transfer Type'),
          type: 'select',
          choices: parentsEmbryoTransferTypeChoices,
          default: null,
          required: true,
        },
        {
          value: 'termination_choice',
          description: t('Would you be willing to reduce/terminate?'),
          label: t('Reduction/termination'),
          type: 'select',
          choices: reductionChoices,
          default: null,
          required: true,
          icon: CheckCircleIcon,
        },
        {
          value: 'delivery_location_preference',
          description: t('Do you have a delivery location preference?'),
          label: t('Delivery Location Preference'),
          type: 'select',
          choices: deliveryLocationPreference,
          default: null,
          required: true,
        },
        {
          value: 'delivery_care_preference',
          description: t('Do you have a care preference for the delivery?'),
          label: t('Care preference'),
          type: 'select',
          choices: deliveryCareChoices,
          default: null,
          required: true,
        },
        {
          value: 'closeness_importance',
          description: t(
            'How important is it for you to be close with your surrogate?',
          ),
          label: t('Strong Relationship'),
          type: 'radio_range',
          choices: importanceChoices,
          default: null,
          required: true,
        },
        {
          value: 'after_delivery_relationship',
          description: t(
            'What type of relationship with your surrogate are you hoping for after delivery?',
          ),
          label: t('Preferred type of relationship after delivery'),
          type: 'select',
          choices: afterDeliveryRelationshipChoices,
          default: null,
          required: true,
        },
        {
          value: 'agency',
          label: t('Surrogate Journey Management'),
          description: t(
            'Are you using an agency or planning a different management journey?',
          ),
          type: 'select',
          choices: agencyChoices,
          default: null,
          required: true,
          icon: UserGroupIcon,
        },
        {
          value: 'agency_name',
          description: t('If an agency, which one are you working with?'),
          label: t('Agency name'),
          type: 'text',
          default: null,
          required: true,
          validationSchema: Yup.string()
            .nullable()
            .when('agency', {
              is: 'agency',
              then: Yup.string().nullable().required('This field is required'),
            }),
          disabled: ({ agency }) => agency !== 'agency',
        },
        {
          value: 'clinic',
          description: t('Do you have a clinic?'),
          label: t('Working with a clinic'),
          type: 'radio',
          choices: radioChoices,
          default: null,
          required: true,
        },
        {
          value: 'clinic_name',
          label: t('Clinic name'),
          description: t('If so, what clinic are you working with?'),
          type: 'text',
          default: null,
          required: true,
          validationSchema: Yup.string()
            .nullable()
            .when('clinic', {
              is: true,
              then: Yup.string().nullable().required('This field is required'),
            }),
          disabled: ({ clinic }) => !clinic || clinic?.toString() === 'false',
        },
      ],
    },
  };
